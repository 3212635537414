import {DateTime} from 'luxon';
import React from 'react';
import './timerPage.css';
class TimerPage extends React.Component {

  componentDidMount(){
    document.title = 'No more Reddit';
    let countUpDate = DateTime.fromISO('2023-06-23T18:00:00', {zone: 'Pacific/Honolulu'}).toMillis();

    let timerInterval = setInterval(function() {
        let now = DateTime.local().toMillis();
        let distance = now - countUpDate;

        let days = Math.floor((distance / (1000*60*60*24)));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        document.getElementById("timer").innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }, 1000);
  }

render(){
return (
  <div id="timer"></div>
)

}

}

export default TimerPage;