import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HexadecimalPage from './HexadecimalPage.jsx';
import TimerPage from './TimerPage.jsx';

const HomePage = () => <div style={{padding: '20px'}}>♪(ノ￣ー￣)ノ♪</div>;

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/hexadecimal">
            <HexadecimalPage />
          </Route>
          <Route path="/no-more-reddit">
            <TimerPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
