import {Application, settings, Graphics, Sprite, Text} from 'pixi.js';
import hexadecimal from './assets/hexadecimal.mp3';
import { odeaPastEntries,g1,g2,g3,p1,w1,w2,w3,w4,w5,w6,w7,r1,r2,r3,purple } from './entries';

import React from 'react';

class HexadecimalPage extends React.Component {

  componentDidMount(){
    document.title = 'Hexadecimal';
    this.init()
  }

  componentWillUnmount(){
    this.app.stop(); // stop the PIXI ticker
    this.app.stage.destroy(true); // remove all children and set the stage to null
    this.app.renderer.destroy(true); // destroy renderer, remove canvas element and context
    this.app = null; // set the app to null
 }

 init(){
  settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;
 const app = new Application({
    resizeTo: window,
    antialias:true
});
app.stage.eventMode = 'auto';
this.app = app;
this.container.appendChild(app.view);

const odeaDot = makeDot(0x00DDEA);
const odeaDot2 = makeDot(0x00DDEA);
const faiveaDot = makeDot(0x5eeeaa);
const faiveaDot2 = makeDot(0x5eeeaa);
const faiveaDot3 = makeDot(0x5eeeaa);
const fffDot = makeDot(0xFFFFFF);
const fff2Dot = makeDot(0xFFFFFF);
const fff3Dot = makeDot(0xFFFFFF);
const fff4Dot = makeDot(0xFFFFFF);
const fdaffaDot = makeDot(0xfdaffa);
const fdaffaDot2 = makeDot(0xfdaffa);
const be0eaaDot = makeDot(0xbe0eaa);
const be0eaa2Dot = makeDot(0xbe0eaa);
const be0eaa3Dot = makeDot(0xbe0eaa);
const neinbakaDot = makeDot(0x9baaca);

function makeDot(color) {
    const dot = new Graphics();
    dot.beginFill(color);
    dot.drawCircle(0, 0, 40);
    dot.endFill();
    const dotSprite = new Sprite();
    dotSprite.addChild(dot);
    dotSprite.alpha = 0;
    app.stage.addChild(dotSprite);
    return dotSprite;
}

function makeText(color,i){
  const text = new Text(color, {
    fontFamily: 'monospace',
    fontSize: 18,
    fontStyle: 'italic',
    fill: color
  });
  text.position.set(i* app.screen.width /6+ 80, app.screen.height* 0.96 );
  text.alpha = 0;
  app.stage.addChild(text);
  return text;
}
const recordDims ={w:1536, h:739};
let ratioW = app.screen.width / recordDims.w;
let ratioH = app.screen.height / recordDims.h;


window.addEventListener('resize', () => {
  setTimeout(()=>{
    ratioW = app.screen.width / recordDims.w;
    ratioH = app.screen.height / recordDims.h;
    let texts = [oddeaText,faiveaText,fffText,fdaffaText,be0eaaText,neinbacaText];
    for(var i=0; i<texts.length;i++){
      texts[i].position.set(i* app.screen.width /6+ 80, app.screen.height* 0.96 );
    } 
    startButton.x = app.screen.width /2;
    startButton.y = app.screen.height / 2;
  },50);
 
}, true);

const startButton = new Text('Listen', {fill: 0xffffff});
startButton.eventMode = 'static';
startButton.buttonMode = true;
startButton.cursor = 'pointer';
startButton.anchor.set(0.5);
startButton.x = app.screen.width /2;
startButton.y = app.screen.height / 2;
app.stage.addChild(startButton);
startButton.on('click', onClickStart);

const oddeaText = makeText('#00ddea',0);
const faiveaText = makeText('#5eeeaa',1);
const fffText = makeText('#ffffff',2);
const fdaffaText = makeText('#fdaffa',3);
const be0eaaText = makeText('#be0eaa',4);
const neinbacaText = makeText('#9baaca',5);


function triggerTextFades(){
  fadeOut(startButton);
  startButton.eventMode = 'none';
  startButton.buttonMode = false;

  setTimeout(()=>{
    fadeIn(oddeaText);
  }, 2000);
  
  setTimeout(()=>{
    fadeIn(faiveaText);
  }, 15000);
  
  setTimeout(()=>{
    fadeIn(fffText);
  }, 21000);
  
  setTimeout(()=>{
    fadeIn(fdaffaText);
  }, 32000);
  
  setTimeout(()=>{
    fadeIn(be0eaaText);
  }, 58000);
  
  setTimeout(()=>{
    fadeIn(neinbacaText);
  }, 71000);
}


const audio = new Audio(hexadecimal);
// const audioBlue = new Audio('./assets/blue.mp3');
// const audioBlue2 = new Audio('./assets/blue.mp3');
// const audioGreen = new Audio('./assets/green.mp3');
// const audioPink = new Audio('./assets/pink.mp3');
// const audioRed = new Audio(red);
// const audioPurpleGrey = new Audio(purpleGrey);
// const audioWhite = new Audio('./assets/white.mp3');

// const audios = {
//  'blue': {audio: audioBlue, isPlaying: false},
//  'blue2': {audio: audioBlue2, isPlaying: false},
//  'green': {audio: audioGreen, isPlaying: false}, 
//  'white': {audio: audioWhite, isPlaying: false},
//  'pink': {audio: audioPink, isPlaying: false},
//  'red': {audio: audioRed, isPlaying: false},
//  'purpleGrey': {audio:audioPurpleGrey, isPlaying:false},
// };

let isPlaying = false;
let entries = [];
function onClickStart(){
    if (isPlaying) {
        audio.pause();
     //   console.log('entries', entries);
        
    } else{
        audio.play();
        triggerTextFades();
       // start = Date.now();
        isPlaying = true;
    }
}

const direction = [1, 1];
const speed = 10;

// var stage = new  Container();
// stage.width = window.width;
// stage.height = window.height;
// stage.interactive = true;
//stage.on('pointermove', handleMove);
//app.stage.addChild(stage);
let isReadingPast = true;
// function handleMove(e){
//     if(isReadingPast) return;
//     if(mouseDown){
//         faiveaDot.alpha = 1;
//         faiveaDot.x = app.renderer.plugins.interaction.mouse.global.x;
//         faiveaDot.y = app.renderer.plugins.interaction.mouse.global.y;
//        if(isPlaying) entries.push({x:faiveaDot.x,y:faiveaDot.y,isPressed:true, timestamp: Date.now()-start});
//     } else {
//         faiveaDot.alpha = 0;
//        if(isPlaying) entries.push({x:faiveaDot.x,y:faiveaDot.y,isPressed:false, timestamp: Date.now()-start});
//     }
// }
// let mouseDown = false;
// document.addEventListener('mousedown', ()=>{mouseDown = true;});
// document.addEventListener('mouseup', ()=>{mouseDown = false;});
// document.addEventListener('mousemove', handleMove);


const touchMoves = [];

function start(){
  console.log('start');
  start = Date.now(); 
}

// window.addEventListener('touchstart', function(event) {
//   console.log('touchstart');
//   be0eaaDot.alpha = 1;
//   be0eaaDot.x = event.touches[0].pageX;
//   be0eaaDot.y = event.touches[0].pageY;
//   touchMoves.push({x:be0eaaDot.x,y:be0eaaDot.y,isPressed:true, timestamp: Date.now()-start});

//   // add touchmove event listener to the window
//   window.addEventListener('touchmove', recordTouchMove);
// });

//   // add touchend event listener to the window
// window.addEventListener('touchend', function(event) {
//   // remove touchmove event listener from the window
//   window.removeEventListener('touchmove', recordTouchMove);

//   // log touch move data to the console
//   console.log(JSON.stringify(touchMoves));
//   touchMoves.length = 0;
// });

// define touchmove event handler
// function recordTouchMove(event) {
//   be0eaaDot.alpha = 1;
//   be0eaaDot.y = event.touches[0].pageY;
//   const scale = (event.touches[0].pageX - touchMoves[0].x) / 40 ;
//   be0eaaDot.scale.x = scale;
//   be0eaaDot.scale.y = scale;
//   touchMoves.push({x:be0eaaDot.x,y:be0eaaDot.y, scale: scale, isPressed:true, timestamp: Date.now()-start});
// }

// const historySize = 3; // Change this value to control the length of the trail
// const history = [];
// for (let i = 0; i < historySize; i++) {
//     history.push(new  Point());
// }

// const trailContainer = new  Container();
// app.stage.addChild(trailContainer);

// const trailSprites = [];
// for (let i = 0; i < historySize; i++) {
//   const dot = new  Graphics();
//   dot.beginFill(0xbe0eaa);
//   dot.drawCircle(0, 0, 40);
//   dot.endFill();
//   const trailSprite = new  Sprite();
//   trailSprite.addChild(dot);
//   trailSprite.scale.x = 0.5;
//   trailSprite.scale.y = 0.5;
//     trailSprite.anchor.set(0.5);
//     trailSprite.alpha = (i + 1) / historySize;
//     trailContainer.addChild(trailSprite);
//     trailSprites.push(trailSprite);
// }

// function recordTouchMove(event) {
//   be0eaaDot.alpha = 1;
//   be0eaaDot.scale.x =0.5;
//   be0eaaDot.scale.y = 0.5;
//   be0eaaDot.y = event.touches[0].pageY;
//   be0eaaDot.x = event.touches[0].pageX;
//   // history.shift();
//   // history.push(new  Point(be0eaaDot.x, be0eaaDot.y));

//   // // Update the trail sprites' positions based on the history array
//   // for (let i = 0; i < historySize; i++) {
//   //     const position = history[i];
//   //     const trailSprite = trailSprites[i];
//   //     trailSprite.x = position.x;
//   //     trailSprite.y = position.y;
//   // }
  
//   touchMoves.push({x:be0eaaDot.x,y:be0eaaDot.y, isPressed:true, timestamp: Date.now()-start});
// }


// window.addEventListener('keypress', (e) => {
//   if(e.key == 'b' && !audios.blue.isPlaying){
//     audios.blue.audio.play();
//     audios.blue.isPlaying = true;
//   }else if(e.key === 'b' && audios.blue.isPlaying){
//     audios.blue.audio.pause();
//     audios.blue.isPlaying = false;
//   }
//   if(e.key == 'n' && !audios.blue2.isPlaying){
//     audios.blue2.audio.play();
//     audios.blue2.isPlaying = true;
//   }else if(e.key === 'n' && audios.blue2.isPlaying){
//     audios.blue2.audio.pause();
//     audios.blue2.isPlaying = false;
//   }
//   else if(e.key == 'g' && !audios.green.isPlaying){
//     start();
//     audios.green.audio.play();
//     audios.green.isPlaying = true;
//   }
//   else if(e.key === 'g' && audios.green.isPlaying){
//     audios.green.audio.pause();
//     audios.green.isPlaying = false;
//   }
//   else if(e.key == 'w' && !audios.white.isPlaying){
//     start();
//     audios.white.audio.currentTime = 0;
//     audios.white.audio.play();
//     audios.white.isPlaying = true;
//   }
//   else if(e.key === 'w' && audios.white.isPlaying){
//     audios.white.audio.pause();
//     audios.white.isPlaying = false;
//   }
//   else if(e.key == 'p' && !audios.pink.isPlaying){
//     start();
//     audios.pink.audio.currentTime = 0;
//     audios.pink.audio.play();
//     audios.pink.isPlaying = true;
//   }
//   else if(e.key === 'p' && audios.pink.isPlaying){
//     audios.pink.audio.pause();
//     audios.pink.isPlaying = false;
//   }
//   else if(e.key == 'r' && !audios.red.isPlaying){
//     start();
//     audios.red.audio.currentTime = 0;
//     audios.red.audio.play();
//     audios.red.isPlaying = true;
//   }
//   else if(e.key === 'r' && audios.red.isPlaying){
//     audios.red.audio.pause();
//     audios.red.isPlaying = false;
//   }
//   else if(e.key == 'l' && !audios.purpleGrey.isPlaying){
//     start();
//     audios.purpleGrey.audio.currentTime = 0;
//     audios.purpleGrey.audio.play();
//     audios.purpleGrey.isPlaying = true;
//   }
//   else if(e.key === 'l' && audios.purpleGrey.isPlaying){
//     audios.purpleGrey.audio.pause();
//     audios.purpleGrey.isPlaying = false;
//   }
// })


app.ticker.add(delta => {
    if(isPlaying && isReadingPast) {
        for(var i=0; i < dotData.length; i++){
          updateDot(dotData[i]);
        }
    }
});
let scale = 1;
let delta = 0.0001;

function animate(sprite) {
  scale += delta;
  sprite.scale.x = scale;
  sprite.scale.y = scale;
  if(scale < 2){
    requestAnimationFrame(()=>animate(sprite));
  }
  
}

function getPositionAtTime(kf1, kf2, currentTime, dot) {
  if (currentTime < kf1.timestamp) {
    return {x:kf1.x,y:kf1.y};
  }

  if (currentTime > kf2.timestamp) {
    dot.entryIndex++;
    return {x:kf2.x,y:kf2.y};
  }

  const progress = (currentTime - kf1.timestamp) / (kf2.timestamp - kf1.timestamp);
  const x = kf1.x + progress * (kf2.x - kf1.x);
  const y = kf1.y + progress * (kf2.y - kf1.y);

  return { x, y };
}

function updateDot(dot){
    const i = dot.entryIndex;
    if(i === dot.entries.length -1){
      //if(dot.dot.alpha > 0 && dot.dot.alpha < 1) dot.dot.alpha -= 0.02;
      return;
    }
    
    if(dot.entries[i].timestamp + dot.offset <= audio.currentTime * 1000 + 30){
  
      if(dot.entries[i].scale){
        dot.dot.scale.x = dot.entries[i].scale;
        dot.dot.scale.y = dot.entries[i].scale;
      }
      if(i===1 && dot.mode === 'scale'){
        animate(dot.dot);
      }
      const pos = getPositionAtTime(dot.entries[i],dot.entries[i+1], audio.currentTime*1000, dot);
        dot.dot.x = pos.x * ratioW;
        dot.dot.y = pos.y * ratioH;  
        if(dot.entries[i].isPressed && dot.entries[i+20] && dot.entries[i+20].isPressed){
            if(dot.dot.alpha < 1) dot.dot.alpha += 0.02;
            if(dot.mode === 'scale') dot.dot.alpha = 1;
        } else {
           if(dot.dot.alpha > 0) dot.dot.alpha -= 0.06;
          // console.log(dot.dot.alpha);
        }
        //if(i < dot.entries.length-1) dot.entryIndex++; 
    }
 }
let deltaAlpha = 0.01;
function fadeIn(sprite) {
  sprite.alpha += deltaAlpha;
  if(sprite.alpha < 1){
    requestAnimationFrame(() => fadeIn(sprite));
  }
}

function fadeOut(sprite) {
  sprite.alpha -= deltaAlpha;
  if(sprite.alpha > 0){
    requestAnimationFrame(() => fadeOut(sprite));
  }
}


  const dotData = [{dot: odeaDot, entryIndex: 0, entries: odeaPastEntries, offset: 0}, 
    {dot: odeaDot2, entryIndex: 0, entries: odeaPastEntries, offset: 15500},
    {dot: odeaDot, entryIndex: 0, entries: odeaPastEntries, offset: 15500*2},
    {dot: faiveaDot, entryIndex: 0, entries: g1, offset: 14000},
    {dot: faiveaDot2, entryIndex: 0, entries: g2, offset: 14000},
    {dot: faiveaDot3, entryIndex: 0, entries: g3, offset: 13500},
    {dot: faiveaDot, entryIndex: 0, entries: g1, offset: 8500 +14000},
    {dot: faiveaDot2, entryIndex: 0, entries: g2, offset: 8500 +14500},
    {dot: faiveaDot3, entryIndex: 0, entries: g3, offset: 8500 +14500},
    {dot: faiveaDot, entryIndex: 0, entries: g1, offset: 8500*2+14000},
    {dot: faiveaDot2, entryIndex: 0, entries: g2, offset: 8500*2+14500},
    {dot: faiveaDot3, entryIndex: 0, entries: g3, offset: 8500*2+14500},
    {dot: faiveaDot, entryIndex: 0, entries: g1, offset: 18500},
    {dot: faiveaDot2, entryIndex: 0, entries: g2, offset: 18000},
    {dot: faiveaDot3, entryIndex: 0, entries: g3, offset: 18000},
    {dot: faiveaDot, entryIndex: 0, entries: g1, offset: 8500 +19500},
    {dot: faiveaDot2, entryIndex: 0, entries: g2, offset: 8500 +18500},
    {dot: faiveaDot3, entryIndex: 0, entries: g3, offset: 8500 +18500},
    {dot: fffDot, entryIndex: 0, entries: w1, offset:  21000, mode: 'scale'},
    {dot: fff2Dot, entryIndex: 0, entries: w2, offset: 21000, mode: 'scale'},
    {dot: fff3Dot, entryIndex: 0, entries: w3, offset: 21000, mode: 'scale'},
    {dot: fff4Dot, entryIndex: 0, entries: w4, offset: 21000,  mode: 'scale'},
    {dot: fffDot, entryIndex: 0, entries: w5, offset:  21000, mode: 'scale'},
    {dot: fff2Dot, entryIndex: 0, entries: w6, offset:  21000, mode: 'scale'},
    {dot: fff3Dot, entryIndex: 0, entries: w7, offset:  21000, mode: 'scale'},

    {dot: fffDot, entryIndex: 0, entries: w1, offset:  21000 + 14000, mode: 'scale'},
    {dot: fff2Dot, entryIndex: 0, entries: w2, offset: 21000 + 14000, mode: 'scale'},
    {dot: fff3Dot, entryIndex: 0, entries: w3, offset: 21000 + 14000, mode: 'scale'},
    {dot: fff4Dot, entryIndex: 0, entries: w4, offset: 21000 + 14000,  mode: 'scale'},
    {dot: fffDot, entryIndex: 0, entries: w5, offset:  21000 + 14000, mode: 'scale'},
    {dot: fff2Dot, entryIndex: 0, entries: w6, offset:  21000 + 14000, mode: 'scale'},
    {dot: fff3Dot, entryIndex: 0, entries: w7, offset:  21000 + 14000, mode: 'scale'},

    {dot: fffDot, entryIndex: 0, entries: w1, offset:  21000 + 14000*2, mode: 'scale'},
    {dot: fff2Dot, entryIndex: 0, entries: w2, offset: 21000 + 14000*2, mode: 'scale'},
    {dot: fff3Dot, entryIndex: 0, entries: w3, offset: 21000 + 14000*2, mode: 'scale'},
    {dot: fff4Dot, entryIndex: 0, entries: w4, offset: 21000 + 14000*2,  mode: 'scale'},
    {dot: fffDot, entryIndex: 0, entries: w5, offset:  21000 + 14000*2, mode: 'scale'},
    {dot: fff2Dot, entryIndex: 0, entries: w6, offset:  21000 + 14000*2, mode: 'scale'},
    {dot: fff3Dot, entryIndex: 0, entries: w7, offset:  21000 + 14000*2, mode: 'scale'},
    
    {dot: fdaffaDot, entryIndex: 0, entries: p1, offset: 31000 },
    {dot: fdaffaDot2, entryIndex: 0, entries: p1, offset: 31000 + 6500 },
    {dot: fdaffaDot, entryIndex: 0, entries: p1, offset: 31000 + 6500*2 },
    {dot: fdaffaDot, entryIndex: 0, entries: p1, offset: 31000 + 6500*3 },

    {dot: be0eaaDot, entryIndex: 0, entries: r1, offset: 55500 },
    {dot: be0eaa2Dot, entryIndex: 0, entries: r2, offset: 55500 },
    {dot: be0eaa3Dot, entryIndex: 0, entries: r3, offset: 55500 },

    {dot: neinbakaDot, entryIndex: 0, entries: purple, offset: 67500 },
  ];

}

  render() {
    return (
      <div ref={(me) => { this.container = me; }} />
    );
  }
}

export default HexadecimalPage;



